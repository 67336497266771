<template>
  <div style="position: relative !important;">
    <h2 class="text-uppercase" style="font-family: 'Raleway light', sans-serif">
      {{ $t("billing_page.invoice_card_title") }}
    </h2>
    <v-row class="mt-5">
      <v-col md="6" class="pr-10">
        <h3 class="font-family-raleway-bold">
          {{ $t("billing_page.upcoming") }}
        </h3>
        <p class="font-family-raleway-medium mt-2">
          {{ $t("billing_page.upcoming_descripiton") }}
        </p>
        <div class="d-flex justify-space-between align-center mt-5 pt-5">
          <span>{{ $t("billing_page.invoices_table_description") }}</span>
          <span>{{ $t("billing_page.invoices_table_amount") }}</span>
        </div>
        <v-divider class="my-5" />
        <div v-if="!upcomingDescription" class="text-center">
          {{ $t("billing_page.no_data") }}
        </div>
        <div
          v-else
          class="d-flex align-baseline justify-space-between font-family-raleway-medium"
        >
          <span>{{ upcomingDescription }}</span>
          <strong class="font-family-raleway-bold">${{ totalUpcoming }}</strong>
        </div>
      </v-col>
      <v-col md="6" class="pl-5">
        <h3 class="font-family-raleway-bold">{{ $t("billing_page.paid") }}</h3>
        <p class="font-family-raleway-medium mt-2">
          {{ $t("billing_page.paid_description") }}
        </p>
        <div v-if="invoicesList.length === 0" class="text-center">
          {{ $t("billing_page.no_data") }}
        </div>
        <div v-else>
          <div
            class="border-16 pa-5 my-2 d-flex align-center font-family-raleway-medium justify-space-between fld"
            style="background-color: #f6f9fc"
            v-for="(item, index) of invoicesList"
            :key="index"
          >
            <h4>{{ getParsedDate(item.dateTime) }}</h4>
            <div class="align-center d-flex fld">
              <strong class="font-family-raleway-bold mr-10"
                >${{ item.total }}</strong
              >
              <v-btn
                class="button font-family-raleway-light text-none"
                style="border-radius: 12px !important"
                @click="downloadPdf(item.invoicePDF)"
                height="48"
              >
                <img :src="downloadIcon" />
                <h4>{{ $t("billing_page.download_invoice") }}</h4>
              </v-btn>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <!--    <CoveredLoader v-if="isGettingInvoicesList" />-->
  </div>
</template>

<script>
import { authService } from "@/services/auth";
import downloadIcon from "@/assets/icon/download.svg";
import moment from "moment";
export default {
  name: "InvoiceSection",
  data() {
    return {
      downloadIcon,
      totalUpcoming: 0,
      invoicesList: [],
      isGettingInvoicesList: false,
      upcomingDescription: "",
    };
  },
  async created() {
    this.isGettingInvoicesList = true;
    try {
      const res = await authService.getInvoices(2);
      this.invoicesList = res.data;
      console.log(res.data);
      console.log(
        res.data.map((item) => {
          return {
            time: item.datetime,
          };
        })
      );
      const response = await authService.getUpComingInvoice();
      this.totalUpcoming = response.data?.total;
      this.upcomingDescription = response.data?.description;
    } catch (e) {
      console.log(e);
    }
    this.isGettingInvoicesList = false;
  },
  methods: {
    getParsedDate(val) {
      return moment(val).format("MMMM");
    },
    downloadPdf(val) {
      let hiddenElement = document.createElement("a");
      hiddenElement.href = encodeURI(val);
      hiddenElement.click();
    },
  },
};
</script>
<style>
.fld{
  flex-wrap: wrap;
}
</style>
